<template>
  <!-- <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>{{ $t("comingSoon") }}</span>
              </label>
            </v-card-title>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row></v-container
  > -->
  <div class="members-directory">
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <div class="car-counts-wrap">
            <h3 class="box-title green-text">
              {{ $t("hotlineNews.newsTitle") }}
            </h3>
            <h4 class="car-count">
              <span>{{ (newsList.meta && newsList.meta.total) || 0 }}</span>
            </h4>
          </div>
        </div>
        <v-spacer></v-spacer>
        <div class="box-top-right">
          <v-text-field hide-details="auto" v-model="searchText" elevation="0" height="40" dense
            :placeholder="$t('hotlineNews.searchForWords')" solo @input="fetchNews" append-icon="mdi-magnify">
          </v-text-field>
        </div>
      </div>
      <div class="custom-card">
        <div class="custom-box">
          <v-data-table :header-props="{ sortIcon: null }" :headers="headers" :items="newsList.data"
            :single-expand="singleExpand" :expanded.sync="expanded" fixed-header :disable-sort="true"
            :show-expand="false" class="color-light-green" :loading="loading" :page.sync="page"
            :items-per-page="itemsPerPage" @page-count="pageCount = $event" hide-default-footer>
            <template v-slot:item.updated_at="{ item }">
              <span class="news-alert">
                <span class="datebreak date-time">
                  <!-- <v-icon aria-hidden="false">
                    mdi-clock-time-five-outline
                  </v-icon> -->
                  <span>{{ item.updated_at | MMDDYYdatefilter }}</span>
                  <v-badge v-if="!item.is_read" inline left content="New" color="error"></v-badge>
                </span>
              </span>
            </template>
            <template v-slot:item.title="{ item }">
              {{ item.title }}
            </template>
            <template v-slot:item.action="{ item }">
              <div class="action-btn-row">
                <a small class="action-btn white--text" @click="viewDetails(item)">
                  <v-icon>mdi-eye</v-icon>
                </a>
              </div>
            </template>
          </v-data-table>
          <div class="text-center custom-pagination">
            <div class="d-flex justify-space-between align-center">
              <div class="text-left">
                <p> {{ $t("pagination.showing") }} {{ startEntry }} {{ $t("pagination.to") }} {{ endEntry }} {{ $t("pagination.of") }} {{ totalEntries }} {{ $t("pagination.entries") }}</p>
              </div>
              <div class="text-right">
                <button @click="previousPage" :disabled="currentPage === 1">
                  {{ $t("pagination.previous") }}
                </button>
                <span class="page-number">{{ currentPage }}</span>
                <button @click="nextPage" :disabled="(currentPage === totalPages) || totalEntries === 0">
                  {{ $t("pagination.next") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "HotlineNews",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      totalEntries: "",


      loading: false,
      page: 1,
      searchText: "",
      pageCount: 0,
      itemsPerPage: 0,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalEntries / this.perPage);
    },
    startEntry() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    endEntry() {
      return Math.min(this.currentPage * this.perPage, this.totalEntries);
    },

    ...mapGetters({
      newsList: "news/getNewsList"
    }),
    headers() {
      return [
        {
          text: this.$t("hotlineNews.date"),
          align: "left",
          value: "updated_at",
          width: "20%",
        },
        {
          text: this.$t("hotlineNews.title"),
          align: "left",
          value: "title",
          width: "65%",
        },
        {
          text: this.$t("hotlineNews.action"),
          align: "center",
          value: "action",
          width: "15%",
        },
      ];
    }
  },
  mounted() {
    this.fetchNews();
    this.$root.$on('reloadFetchNews', () => {
      setTimeout(() => {
        this.fetchNews();
      }, 500);
    })
  },
  methods: {
    ...mapActions({
      getNewsList: "news/getNewsList",
      markAsReadNews: "news/markAsRead",
    }),


    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchNews(); // Fetch new data based on the current page
      }
    },
    async previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchNews(); // Fetch new data based on the current page
      }
    },

    async fetchNews() {
      this.loading = true;
      try {
        if (this.searchText !== "") {
          this.page = 1;
        }
        await this.getNewsList({
          search: this.searchText,
          page: this.currentPage,
          items_per_page: this.perPage,
        });

        if (this.newsList.meta) {
          this.totalEntries = this.newsList.meta.total;
          this.itemsPerPage = parseInt(this.newsList.meta.per_page);
          this.pageCount = this.newsList.meta.last_page;
        } else {
          this.itemsPerPage = this.news.length;
          this.pageCount = 1;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.loading = false;
      }
    },
    viewDetails(item) {
      if (item.is_read == 0) {
        this.markAsReadNews(item);
      }
      this.$router.push({ name: 'ViewHotlineNews', params: { newsContentData: item } });
    }
  },
};
</script>